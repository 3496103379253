import React from 'react'

import Dashboard from './Dashboard'
import Greetings from './Greetings'

export default function Echelon() {
    return (
        <>
            <Dashboard />
            <Greetings />
        </>
    )
}



